var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',{ref:"main"},[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-title p-3 border-bottom"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"me-2"},[_c('i',{staticClass:"uil uil-setting display-6 text-primary"})]),_c('div',[_c('h4',{staticClass:"fw-medium font-size-20 text-info mb-0"},[_vm._v(" "+_vm._s(_vm.title))]),_c('small',{staticClass:"d-block font-size-13 text-muted fw-normal"},[_vm._v("Account Info & Password")])])])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"mt-1"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 col-12"},[_c('h5',{staticClass:"font-size-14 mb-1 text-dark"},[_vm._v("Login Username")]),_c('p',{staticClass:"mb-3 text-primary"},[_vm._v(_vm._s(_vm.username || '-'))])]),(_vm.account_type=='merchant')?_c('div',{staticClass:"col-md-4 col-12"},[_c('h5',{staticClass:"font-size-14 mb-1 text-dark"},[_vm._v("Merchant Code")]),_c('p',{staticClass:"mb-3 text-primary"},[_vm._v(_vm._s(_vm.merchant_code || '-'))])]):_vm._e(),(_vm.account_type=='merchant')?_c('div',{staticClass:"col-md-4 col-12"},[_c('h5',{staticClass:"font-size-14 mb-1 text-dark"},[_vm._v("Merchant Key")]),_c('p',{staticClass:"mb-3 text-primary"},[_vm._v(_vm._s(_vm.merchant_key || '-'))])]):_vm._e()]),_c('form',[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',[_vm._v("Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.name),expression:"formData.name"}],staticClass:"form-control",class:{
                        'is-invalid':
                          _vm.submitted && _vm.$v.formData.name.$error,
                      },attrs:{"type":"text","placeholder":"Name","name":"name"},domProps:{"value":(_vm.formData.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "name", $event.target.value)}}}),(_vm.submitted && _vm.$v.formData.name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.formData.name.required)?_c('span',[_vm._v("Name is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"mb-3 position-relative"},[_c('label',[_vm._v("Current Password")]),((_vm.passwordFieldType_current)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.current_password),expression:"formData.current_password"}],staticClass:"form-control",class:{
                        'is-invalid':
                          _vm.submitted && _vm.$v.formData.current_password.$error,
                      },attrs:{"placeholder":"Current Password","name":"name","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.formData.current_password)?_vm._i(_vm.formData.current_password,null)>-1:(_vm.formData.current_password)},on:{"change":function($event){var $$a=_vm.formData.current_password,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.formData, "current_password", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.formData, "current_password", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.formData, "current_password", $$c)}}}}):((_vm.passwordFieldType_current)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.current_password),expression:"formData.current_password"}],staticClass:"form-control",class:{
                        'is-invalid':
                          _vm.submitted && _vm.$v.formData.current_password.$error,
                      },attrs:{"placeholder":"Current Password","name":"name","type":"radio"},domProps:{"checked":_vm._q(_vm.formData.current_password,null)},on:{"change":function($event){return _vm.$set(_vm.formData, "current_password", null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.current_password),expression:"formData.current_password"}],staticClass:"form-control",class:{
                        'is-invalid':
                          _vm.submitted && _vm.$v.formData.current_password.$error,
                      },attrs:{"placeholder":"Current Password","name":"name","type":_vm.passwordFieldType_current},domProps:{"value":(_vm.formData.current_password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "current_password", $event.target.value)}}}),(_vm.submitted && _vm.$v.formData.current_password.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.formData.current_password.required)?_c('span',[_vm._v("Current password is required.")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"user-select-none",attrs:{"id":"eye_current"},on:{"click":function($event){return _vm.switchVisibility_current()}}},[_c('i',{class:_vm.classicon_current,attrs:{"id":"classicon_current","aria-hidden":"true"}})])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',[_vm._v("New Password")]),_c('div',[((_vm.passwordFieldType)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.password),expression:"formData.password"}],staticClass:"form-control",class:{
                              'is-invalid':
                                _vm.submitted && _vm.$v.formData.password.$error,
                            },attrs:{"name":"password","placeholder":"New Password","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.formData.password)?_vm._i(_vm.formData.password,null)>-1:(_vm.formData.password)},on:{"change":function($event){var $$a=_vm.formData.password,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.formData, "password", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.formData, "password", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.formData, "password", $$c)}}}}):((_vm.passwordFieldType)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.password),expression:"formData.password"}],staticClass:"form-control",class:{
                              'is-invalid':
                                _vm.submitted && _vm.$v.formData.password.$error,
                            },attrs:{"name":"password","placeholder":"New Password","type":"radio"},domProps:{"checked":_vm._q(_vm.formData.password,null)},on:{"change":function($event){return _vm.$set(_vm.formData, "password", null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.password),expression:"formData.password"}],staticClass:"form-control",class:{
                              'is-invalid':
                                _vm.submitted && _vm.$v.formData.password.$error,
                            },attrs:{"name":"password","placeholder":"New Password","type":_vm.passwordFieldType},domProps:{"value":(_vm.formData.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "password", $event.target.value)}}}),(_vm.submitted && _vm.$v.formData.password.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.formData.password.required)?_c('span',[_vm._v("New password is required.")]):_vm._e(),(!_vm.$v.formData.password.minLength)?_c('span',[_vm._v("Password must be at least 8 characters.")]):_vm._e(),(!_vm.$v.formData.password.alpha)?_c('span',[_vm._v(" Password must contain at least one number and both lower and uppercase letters and special characters. (e.g.: MercHant357#@, Admin12#@, etc.)")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"user-select-none",attrs:{"id":"eye"},on:{"click":function($event){return _vm.switchVisibility()}}},[_c('i',{class:_vm.classicon,attrs:{"id":"eyetype","aria-hidden":"true"}})])])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',[_vm._v("Confirm Password")]),((_vm.passwordFieldType2)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.confirmPassword),expression:"formData.confirmPassword"}],staticClass:"form-control",class:{
                            'is-invalid':
                              _vm.submitted && _vm.$v.formData.confirmPassword.$error,
                          },attrs:{"name":"confirmPassword","placeholder":"Confirm Password","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.formData.confirmPassword)?_vm._i(_vm.formData.confirmPassword,null)>-1:(_vm.formData.confirmPassword)},on:{"change":function($event){var $$a=_vm.formData.confirmPassword,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.formData, "confirmPassword", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.formData, "confirmPassword", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.formData, "confirmPassword", $$c)}}}}):((_vm.passwordFieldType2)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.confirmPassword),expression:"formData.confirmPassword"}],staticClass:"form-control",class:{
                            'is-invalid':
                              _vm.submitted && _vm.$v.formData.confirmPassword.$error,
                          },attrs:{"name":"confirmPassword","placeholder":"Confirm Password","type":"radio"},domProps:{"checked":_vm._q(_vm.formData.confirmPassword,null)},on:{"change":function($event){return _vm.$set(_vm.formData, "confirmPassword", null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.confirmPassword),expression:"formData.confirmPassword"}],staticClass:"form-control",class:{
                            'is-invalid':
                              _vm.submitted && _vm.$v.formData.confirmPassword.$error,
                          },attrs:{"name":"confirmPassword","placeholder":"Confirm Password","type":_vm.passwordFieldType2},domProps:{"value":(_vm.formData.confirmPassword)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "confirmPassword", $event.target.value)}}}),(
                            _vm.submitted && _vm.$v.formData.confirmPassword.$error
                          )?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.formData.confirmPassword.required)?_c('span',[_vm._v("Confirm password is required.")]):(
                              !_vm.$v.formData.confirmPassword.sameAsPassword
                            )?_c('span',[_vm._v("Confirm password does not match the new password.")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"user-select-none",attrs:{"id":"eye2"},on:{"click":function($event){return _vm.switchVisibility2()}}},[_c('i',{class:_vm.classicon2,attrs:{"id":"eyetype2","aria-hidden":"true"}})])])])]),_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"text-muted font-size-13"},[_c('i',{staticClass:"uil uil-exclamation-triangle font-size-16 text-warning me-2"}),_vm._v(" Password is Case Sensitive. ")])]),_c('div',{staticClass:"mb-3 d-flex"},[_c('button',{staticClass:"btn btn-light me-2",attrs:{"type":"button","disabled":_vm.loading || _vm.buttonLoading[1]},on:{"click":_vm.resetData}},[_c('i',{staticClass:"uil-redo me-1"}),(_vm.buttonLoading[1])?_c('span',[_vm._v(" Resetting..")]):_c('span',[_vm._v(" Reset ")]),(_vm.buttonLoading[1])?_c('span',{staticClass:"spinner-border spinner-border-sm ms-2",attrs:{"role":"status","aria-hidden":"true"}}):_vm._e()]),_c('button',{staticClass:"btn btn-info me-2",attrs:{"type":"button","disabled":_vm.loading || _vm.buttonLoading[0]},on:{"click":_vm.save}},[_c('i',{staticClass:"uil uil-check me-1"}),(_vm.buttonLoading[0])?_c('span',[_vm._v(" Submitting...")]):_c('span',[_vm._v(" Submit ")]),(_vm.buttonLoading[0])?_c('span',{staticClass:"spinner-border spinner-border-sm ms-2",attrs:{"role":"status","aria-hidden":"true"}}):_vm._e()])])])])])])])])])]),_c('Lottie',{ref:"lottieFunc",attrs:{"path":'',"title":'Loading',"info":'Please wait...',"show":_vm.showLottie}}),_c('Common',{ref:"commonFunc"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }